<template>
  <div class="q-pt-md">
    <q-card
      v-for="(invoice, index) in this.$_.groupBy(this.invoicesPayments, 'advertizer_id')"
      :key="index"
      flat
      bordered
      class="col-6 q-my-md"
    >
      <q-expansion-item
        expand-separator
        :label="`${invoice[0].advertizer_name}` + ' (ID: ' + `${invoice[0].advertizer_id}` + ')'"
        header-class="bg-primary text-white"
        expand-icon-class="text-white"
      >
        <q-card
          v-for="(invoiceAdvertiser, ind) in invoice.filter((obj, index, self) => {
            return index === self.findIndex((t) => t.campaign_id === obj.campaign_id);
          })"
          :key="ind"
        >
          <q-card-section>
            <template>
              <q-expansion-item
                default-opened
                dense
                expand-separator
                header-class="bg-blue-grey-1 text-bold"
                :label="`${invoiceAdvertiser.campaign_name}` + ' (ID: ' + `${invoiceAdvertiser.campaign_id}` + ')'"
              >
                <q-card>
                  <q-card-section>
                    <q-table
                      flat
                      bordered
                      :data="
                        invoice.filter(
                          (item) =>
                            item.advertizer_id == invoiceAdvertiser.advertizer_id &&
                            item.campaign_id == invoiceAdvertiser.campaign_id
                        )
                      "
                      :columns="columns"
                      row-key="id"
                      :pagination="pagination"
                    >
                      <template v-slot:body-cell-invoice_nums="props">
                        <q-td :props="props" v-if="!editInvoiceNums">
                          {{ prettyParse(props.row.invoice_nums) }}
                        </q-td>
                        <q-td :props="props" v-else>
                          <q-input rounded standout="bg-blue-grey" v-model="props.row.invoice_nums" />
                        </q-td>
                      </template>

                      <template v-slot:body-cell-liberation="props">
                        <q-td :props="props">
                          <q-toggle @input="liberateBilling(props.row)" v-model="props.row.liberate" />
                        </q-td>
                      </template>

                      <template v-slot:body-cell-total_received="props">
                        <q-td :props="props" v-if="!editInvoiceReceived">
                          {{ prettyReal(props.row.total_received) }}
                        </q-td>
                        <q-td :props="props" v-else>
                          <q-input rounded standout="bg-blue-grey" type="number" v-model="props.row.total_received" />
                        </q-td>
                      </template>

                      <template v-slot:body-cell-actions="props">
                        <q-td :props="props">
                          <q-btn unelevated round flat icon="mdi-pencil-outline" color="primary" @click="editTables">
                            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                              {{ $t('edit') }}
                            </q-tooltip>
                          </q-btn>

                          <q-btn
                            unelevated
                            round
                            flat
                            icon="mdi-content-save"
                            color="primary"
                            @click="onSave(props.row)"
                          >
                            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                              {{ $t('save') }}
                            </q-tooltip>
                          </q-btn>
                        </q-td>
                      </template>
                    </q-table>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </template>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import FinanceService from '../../services/FinanceService';

export default {
  name: 'ListBilling',
  mixins: [FinancialMixin, FinanceService],
  filters: {},
  data() {
    return {
      sites: [],
      campaigns: [],
      params: null,
      editInvoiceNums: false,
      editInvoiceReceived: false,
      columns: [
        {
          name: 'date',
          required: true,
          label: 'Período',
          align: 'left',
          field: (val) => val,
          format: (val) => this.getDateFormat(val),
          sortable: true
        },
        {
          name: 'pis',
          required: true,
          label: 'Número PI',
          align: 'left',
          field: 'pis',
          sortable: true
        },
        {
          name: 'invoice_nums',
          required: true,
          label: 'Número Fatura',
          align: 'left',
          field: 'invoice_nums',
          sortable: true
        },
        {
          name: 'total_validated',
          required: true,
          label: 'Total Validado',
          align: 'left',
          field: 'total_validated',
          format: (val) =>
            `R$ ${parseFloat(val)
              .toFixed(2)
              .replace('.', ',')
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`,
          sortable: true
        },
        {
          name: 'total_received',
          required: true,
          label: 'Total Recebido',
          align: 'left',
          field: 'total_received',
          format: (val) =>
            `R$ ${parseFloat(val)
              .toFixed(2)
              .replace('.', ',')
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`,
          sortable: true
        },
        {
          name: 'cost',
          required: true,
          label: 'Custo (Afiliado)',
          align: 'left',
          field: 'cost',
          format: (val) =>
            `R$ ${parseFloat(val)
              .toFixed(2)
              .replace('.', ',')
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`,
          sortable: true
        },
        {
          name: 'margin',
          required: true,
          label: 'Margem (Afilio)',
          align: 'center',
          field: 'margin',
          format: (val) =>
            `R$ ${parseFloat(val)
              .toFixed(2)
              .replace('.', ',')
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`,
          sortable: true
        },
        {
          name: 'liberation',
          required: true,
          label: 'Liberação',
          align: 'center',
          field: 'liberate',
          sortable: true
        },
        {
          name: 'actions',
          required: true,
          label: 'Ações',
          align: 'center',
          field: 'id',
          sortable: true
        }
      ],
      pagination: {
        sortBy: 'trademark',
        descending: true,
        rowsPerPage: 10
      },
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]
    };
  },

  methods: {
    prettyParse(value) {
      if (value.length === 0) {
        return null;
      }
      return JSON.parse([value]);
    },

    prettyReal(value) {
      if (value === null) {
        return `R$ 0`;
      }
      return `R$ ${parseFloat(value)
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
    },

    editTables() {
      this.editInvoiceNums = !this.editInvoiceNums;
      this.editInvoiceReceived = !this.editInvoiceReceived;
    },

    getDateFormat(row) {
      return row.month + '/' + row.year;
    },

    removeCampaign(index) {
      this.campaigns.splice(index, 1);
    },

    removeSite(index) {
      this.sites.splice(index, 1);
    },

    async onSave(rowData) {
      console.log(rowData);
      if (rowData.invoice_nums.length === 0) {
        this.errorNotify('Informe o número da fatura');
        return;
      }
      if (rowData.total_received === '' || rowData.total_received === null) {
        this.errorNotify('Informe o Total Recebido');
        return;
      }
      this.onLoading(true);
      try {
        const { status } = await this.editInvoice(
          rowData.id,
          JSON.stringify([rowData.invoice_nums]),
          rowData.total_received
        );
        if (status === 200) {
          this.editTables();

          this.successNotify('Fatura atualizada com sucesso');
        }
      } catch (error) {
        this.errorNotify(error.response.data.data.msg);
      } finally {
        this.onLoading(false);
      }
    },

    async liberateBilling(rowData) {
      this.showDialog(
        'Liberar faturamento',
        `Deseja liberar as comissões da campanha ${rowData.campaign_name}. do período de ${
          this.months[rowData.month - 1]
        }/${rowData.year}?`,
        () => {
          this.onOk(rowData.id);
        },
        () => {
          this.onCancel(rowData.id);
        }
      );
    },

    async onOk(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.toggleLiberateInvoice(id);
        if (status === 200) this.successNotify(data.data.msg);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
        this.onCancel(id);
      } finally {
        this.onLoading(false);
      }
    },

    onCancel(id) {
      this.invoicesPayments.map((invoice) => {
        if (invoice.id === id) {
          invoice.liberate = !invoice.liberate;
        }
      });
    }
  }
};
</script>
