<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar PI</q-tooltip>
      </q-btn>
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-form @submit="onFilterBilling">
        <q-card-section class="q-pt-none q-col-gutter-md">
          <div class="row q-gutter-x-md">
            <div class="col-6">
              <q-select
              class="q-mb-md"
              v-model="campaign"
              clearable
              use-input
              input-debounce="200"
              @filter="filterComboboxCampaign"
              :options="campaignsFiltered"
              label="Campanha"
              option-label="label"
              option-value="id"
              outlined
              />
            </div>
            
            <div class="col-5">
              <date-input
              buttons
              outlined
              label="Período"
              :v-model="date"
              @update="(value) => (date = value)"
              clearable
              range
              :rules="[(val) => !!val || 'O período é obrigatório.']"
              />
            </div>
          </div>

          <div class="col-12 text-right">
            <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" type="submit" />
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import AdvertiserService from '../../services/AdvertiserService';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderPayInvoices',
  mixins: [FinancialMixin, AdvertiserService],
  components: {DateInput},

  props: {
    campaignsAll: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      filter: true,
      campaign: null,
      campaignsFiltered: null,
      date: null,
    };
  },

  methods: {
    filterComboboxAdvertiser(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.advertisersFiltered = this.advertisers;
        this.advertisersFiltered = this.advertisers.filter((v) => v.trademark.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterComboboxCampaign(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignsFiltered = this.campaignsAll;
        this.campaignsFiltered = this.campaignsAll.filter((v) => v.label.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    extractDates(){
      if(this.date){
        const [start, end] = this.date.split(' - ');
        const [startMonth, startYear] = start.split('/').map(Number).slice(1);
        const [endMonth, endYear] = end.split('/').map(Number).slice(1);
        return {startMonth, startYear, endMonth, endYear};
      }
      return {startMonth: null, startYear: null, endMonth: null, endYear: null};
    },

    async onFilterBilling() {
      const {startMonth, startYear, endMonth, endYear} = this.extractDates();
      await this.ActionSetFilterInvoicesPayments();
      await this.getInvoicesPayments(
        null,
        this.campaign ? this.campaign.id : null,
        startMonth,
        endMonth,
        startYear,
        endYear
      );
    }
  }
};
</script>
