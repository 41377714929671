import { render, staticRenderFns } from "./ListBilling.vue?vue&type=template&id=72c5d4c5"
import script from "./ListBilling.vue?vue&type=script&lang=js"
export * from "./ListBilling.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QExpansionItem,QCardSection,QTable,QTd,QInput,QToggle,QBtn,QTooltip});
