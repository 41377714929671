<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Faturas</q-tooltip>
      </q-btn>
      <!-- @click="ActionSetDialogCampaign(true)" -->
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-form @submit="onFilterBilings">
        <q-toolbar>
          <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
          <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-4">
            <q-select
              class="q-mb-md"
              v-model="params.campaign"
              clearable
              use-input
              input-debounce="200"
              @filter="filterListCampaign"
              :options="campaignsFiltered"
              label="Campanha"
              option-label="label"
              option-value="id"
              outlined
            />
          </div>

          <div class="col-4">
            <q-select
              class="q-mb-md"
              v-model="params.affiliate"
              clearable
              use-input
              input-debounce="200"
              @filter="filterListAffiliate"
              :options="affiliatesFiltered"
              label="Afiliado"
              option-label="label"
              option-value="id"
              outlined
            />
          </div>

          <div class="col-4">
            <date-input
              buttons
              outlined
              label="Período"
              :v-model="params.paymentDate"
              @update="(value) => (params.paymentDate = value)"
              :rules="[isRequired]"
              clearable
              range
            />
          </div>

          <div class="col-6 q-pt-none">
            <span class="text-weight-medium q-mb-none">Status</span>
            <div class="q-gutter-xs">
              <q-radio
                v-model="params.status"
                val="1"
                keep-color
                label="Pendente Liberação"
                class="text-warning"
                color="warning"
              />
              <q-radio
                v-model="params.status"
                val="2"
                keep-color
                label="Liberado"
                class="text-positive"
                color="positive"
              />
              <!-- <q-radio
                v-model="params.status"
                val="3"
                keep-color
                label="Solicitado"
                class="text-negative"
                color="negative"
              />
              <q-radio v-model="params.status" val="5" keep-color label="Pago" class="text-info" color="info" /> -->
            </div>
          </div>

          <div class="col-6 text-right">
            <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" type="submit" />
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import AdvertiserService from '../../services/AdvertiserService';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderApproveInvoices',
  mixins: [FinancialMixin, AdvertiserService],
  components: { DateInput },

  props: {
    campaignsAll: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      filter: true,
      params: {
        campaign: null,
        affiliate: null,
        paymentDate: null,
        status: null
      },
      campaignsFiltered: [],
      affiliatesAll: [],
      affiliatesFiltered: []
    };
  },

  created() {
    this.getAffiliates();
  },

  methods: {
    clearFilter() {
      for (let i in this.params) this.params[i] = null;
      this.ActionSetBillingsByAffiliate();
    },

    async getAffiliates() {
      try {
        const { data, status } = await this.$http.get('/redirect?app=affiliate&path=/api/get/affiliate/filter');
        if (status === 200 || status === 201) {
          const affilates = data.map((item) => ({
            ...item,
            label: `${item.name} (ID:${item.id})`
          }));

          this.affiliatesAll = affilates;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    filterListCampaign(val, update) {
      update(this.defaultFilter(val, 'campaignsFiltered', this.campaignsAll, 'label'));
    },

    filterListAffiliate(val, update) {
      update(this.defaultFilter(val, 'affiliatesFiltered', this.affiliatesAll, 'label'));
    },

    onFilterBilings() {
      let from = this.params.paymentDate.split(' ')[0];
      let to = this.params.paymentDate.split(' ')[2];

      let dateFrom = this.$moment(this.stringToDate(from, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD');

      let dateTo = this.$moment(this.stringToDate(to, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD');

      this.ActionSetFilterBillingsByAffiliate({
        dateFrom: dateFrom,
        dateTo: dateTo,
        campaignId: this.params.campaign ? this.params.campaign.id : null,
        affiliateId: this.params.affiliate ? this.params.affiliate.id : null,
        state_id: this.params.status ? this.params.status : '1,2'
      });
      this.getBillingsByAffiliate(
        dateFrom,
        dateTo,
        this.params.campaign ? this.params.campaign.id : null,
        this.params.affiliate ? this.params.affiliate.id : null,
        this.params.status ? this.params.status : '1,2'
      );
    }
  }
};
</script>
