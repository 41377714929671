<template>
  <q-table
    flat
    bordered
    :data="billingsByAffiliate"
    :columns="columns"
    row-key="id"
    :pagination="pagination"
    selection="multiple"
    :selected.sync="itemSelected"
  >
    <template v-slot:bottom>
      <!-- <pagination-table
              @change="getStatementsAdmin"
              :data="reqPagination"
              :pagination="pagination"
            /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
    <template v-slot:top>
      <div class="q-gutter-x-md">
        <q-btn
          color="positive"
          label="Liberar"
          no-caps
          unelevated
          class="q-px-md"
          :disabled="itemSelected.length == 0"
          @click="onAction('liberate')"
        >
        </q-btn>
        <q-btn
          color="primary"
          label="Cancelar liberação"
          no-caps
          unelevated
          class="q-px-md"
          :disabled="itemSelected.length == 0"
          @click="onAction('cancel')"
        >
        </q-btn>
      </div>
    </template>
  </q-table>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';

export default {
  name: 'ListApproveInvoices',
  mixins: [FinancialMixin],

  data() {
    return {
      itemSelected: [],
      params: {
        payout: null,
        paymentDay: null
      },
      columns: [
        {
          name: 'affilate_id',
          required: true,
          label: 'ID Afiliado',
          align: 'left',
          field: 'affiliate_id',
          sortable: true
        },
        {
          name: 'affiliate_idv2',
          required: true,
          label: 'ID v2',
          align: 'left',
          field: 'affiliate_idv2',
          sortable: true
        },
        {
          name: 'affilate',
          required: true,
          label: 'Afiliado',
          align: 'left',
          field: 'affiliate_name',
          sortable: true
        },
        {
          name: 'campaign_id',
          required: true,
          label: 'ID Campanha',
          align: 'left',
          field: 'campaign_id',
          sortable: true
        },
        {
          name: 'date',
          required: true,
          label: 'Mês/ano Referente',
          align: 'left',
          field: 'date',
          sortable: true
        },
        {
          name: 'campaign_name',
          required: true,
          label: 'Campanha',
          align: 'left',
          field: 'campaign_name',
          sortable: true
        },
        {
          name: 'value',
          required: true,
          label: 'Valor da Fatura',
          align: 'left',
          field: 'value',
          sortable: true
        },
        {
          name: 'state_id',
          required: true,
          label: 'Status',
          field: 'state_id',
          format: (state_id) => this.formatStates(state_id),
          align: 'center'
        }
      ],
      pagination: {
        sortBy: 'trademark',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getBillingsByAffiliate(
          this.filterBillingsByAffiliate.dateFrom,
          this.filterBillingsByAffiliate.dateTo,
          this.filterBillingsByAffiliate.campaignId,
          this.filterBillingsByAffiliate.affiliateId,
          this.filterBillingsByAffiliate.state_id,
          this.currentPage,
          this.pagination.rowsPerPage
        );
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  methods: {
    onAction(action) {
      const itemsData = this.itemSelected
        .filter((item) => (action == 'liberate' ? item.state_id === 1 : item.state_id === 2))
        .map((item) => ({
          dataInicio: this.filterBillingsByAffiliate.dateFrom,
          dataFim: this.filterBillingsByAffiliate.dateTo,
          affiliateId: item.affiliate_id,
          state_id: item.state_id,
          campaignId: item.campaign_id,
          liberate: action === 'liberate' ? true : false
        }));

      if (itemsData.length == 0) {
        this.errorNotify(
          action == 'liberate'
            ? 'Comissão selecionada já liberada!'
            : 'Comissão selecionada já está pendente de liberação!'
        );
        // this.itemSelected = [];
        return;
      }

      this.$q
        .dialog({
          title: action == 'liberate' ? 'Liberar valores de comissão' : 'Cancelar liberação de comissão',
          message:
            action == 'liberate'
              ? 'Confirma a liberação para os afiliados selecionados?'
              : 'Confirma o cancelamento da liberação para os afiliados selecionados?',
          persistent: true,
          ok: action == 'liberate' ? { color: 'positive' } : { color: 'primary' },
          cancel: 'Cancelar'
        })
        .onOk(() => {
          this.onActionCommission(action, itemsData);
        });
    },

    async onActionCommission(action, itemsData) {
      this.onLoading(true);

      try {
        const { status } = await this.$http.post(`/v2/redirect`, {
          app: 'finance',
          path: `/api/statements_admin/liberate`,
          params: {
            data: itemsData
          }
        });
        if (status === 200) {
          this.getBillingsByAffiliate(
            this.filterBillingsByAffiliate.dateFrom,
            this.filterBillingsByAffiliate.dateTo,
            this.filterBillingsByAffiliate.campaignId,
            this.filterBillingsByAffiliate.affiliateId,
            this.filterBillingsByAffiliate.state_id,
            this.currentPage,
            this.pagination.rowsPerPage
          );
          this.itemSelected = [];
          this.successNotify(
            action == 'liberate' ? 'Comissões LIBERADAS com sucesso!' : 'Liberações CANCELADAS com sucesso!'
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    formatPaymentDate(date) {
      if (date == null) return 'Sem data definida';
      return (val) => this.$moment(val).format('L');
    },

    formatStates(state) {
      switch (state) {
        case 1:
          return 'Pendente Liberação';

        case 2:
          return 'Liberado';

        case 3:
          return 'Solicitado';

        case 4:
          return 'Aprovado';

        case 5:
          return 'Pago';

        case 11:
          return 'Recusado';

        case 12:
          // return 'Pré-Aprovado';
          return 'Solicitado';

        default:
          return '-';
      }
    }
  }
};
</script>
