<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Faturamento</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Faturamento" exact />
      </q-breadcrumbs>
    </div>

    <q-card flat bordered class="q-mx-md">
      <q-tabs
        v-model="tab"
        dense
        className="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="tabCampaign" label="Liberação por Campanha" />
        <q-tab name="tabAffiliate" label="Liberação por Afiliado" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="tabCampaign">
          <header-billing :campaignsAll="campaignsAll"></header-billing>
          <list-billing></list-billing>
        </q-tab-panel>

        <q-tab-panel name="tabAffiliate">
          <header-billing-by-affiliate :campaignsAll="campaignsAll"></header-billing-by-affiliate>
          <list-billing-by-affiliate></list-billing-by-affiliate>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-layout>
</template>

<script>
import HeaderBilling from '../../components/financial/HeaderBilling.vue';
import ListBilling from '../../components/financial/ListBilling.vue';
import HeaderBillingByAffiliate from '../../components/financial/HeaderBillingByAffiliate.vue';
import ListBillingByAffiliate from '../../components/financial/ListBillingByAffiliate.vue';
import AdvertiserService from '../../services/AdvertiserService';

export default {
  name: 'Billing',
  components: { HeaderBilling, ListBilling, HeaderBillingByAffiliate, ListBillingByAffiliate },
  mixins: [AdvertiserService],

  data() {
    return {
      tab: 'tabCampaign',
      campaignsAll: []
    };
  },

  created() {
    this.getCampaigns();
  },

  methods: {
    async getCampaigns() {
      try {
        const { data, status } = await this.listCampaign();
        if (status === 200 || status === 201) {
          const campaigns = data.map((item) => ({
            ...item,
            label: `${item.name} (ID:${item.id})`
          }));
          this.campaignsAll = campaigns;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    }
  }
};
</script>
